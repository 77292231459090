import React from "react";
import {
  SEO,
  PageLayout,
  ContactForm,
  createDefaultMessageFromState,
  Contact,
} from "@bluefin/components";
import { Grid, Image, List, Table } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";
import "../style/catering.css";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null} className={"catering-page-container"}>
          <Grid
            stackable={true}
            className={"component-section-container catering-page"}
            verticalAlign={"middle"}
            textAlign={"left"}
            centered={true}
          >
            <Grid.Column width={10}>
              <h1>Special Catering</h1>
              <ContactForm
                constructMessage={createDefaultMessageFromState}
                fields={[
                  {
                    label: "name",
                    placeholder: "Full Name",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "email",
                    placeholder: "Email",
                    type: "input",
                    inputType: "email",
                    required: true,
                  },
                  {
                    label: "phone",
                    placeholder: "Phone Number",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "address",
                    displayLabel: "Address",
                    placeholder: "Address",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "guest",
                    displayLabel: "Number Of Guests",
                    placeholder: "Number Of Guests",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "delivery",
                    displayLabel: "Delivery",
                    type: "select",
                    options: [
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ],
                  },
                  {
                    label: "budget",
                    displayLabel: "Budget",
                    placeholder: "Budget",
                    type: "input",
                    required: true,
                  },
                  {
                    label: "cateringType",
                    displayLabel: "Catering Type",
                    type: "select",
                    required: true,
                    options: [
                      { label: "Full Service", value: "Full Service" },
                      { label: "Buffet", value: "Buffet" },
                      { label: "Pre-packaged", value: "Pre-packaged" },
                      { label: "Popup", value: "Popup" },
                    ],
                  },
                  {
                    label: "eventDate",
                    displayLabel: "Event Date",
                    placeholder: "Event Date",
                    type: "input",
                    inputType: "date",
                    required: true,
                  },
                  {
                    label: "startTime",
                    displayLabel: "Start Time",
                    placeholder: "Start Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "endTime",
                    displayLabel: "End Time",
                    placeholder: "End Time",
                    type: "input",
                    inputType: "time",
                    required: false,
                  },
                  {
                    label: "referenceSource",
                    displayLabel: "How did you hear about us ?",
                    type: "select",
                    required: true,
                    options: [
                      {
                        label: "Saw us at a food festival or event",
                        value: "Saw us at a food festival or event",
                      },
                      {
                        label: "Visited our popup location at tiki pete",
                        value: "Visited our popup location at tiki pete",
                      },
                      { label: "Instagram", value: "Instagram" },
                      { label: "Yelp", value: "Yelp" },
                      { label: "Word Of Mouth", value: "Word Of Mouth" },
                      { label: "Other", value: "Other" },
                    ],
                  },
                  {
                    label: "otherReferenceSource",
                    displayLabel:
                      "If other please let us know how did you hear from us",
                    placeholder:
                      "If other please let us know how did you hear from us",
                    type: "input",
                    required: false,
                  },
                  {
                    label: "message",
                    placeholder: "Additional Message",
                    type: "textarea",
                    required: false,
                  },
                ]}
                buttonText={"Send"}
                centeredHeaders={true}
                className={""}
                fluidButton={true}
                header={""}
                inline={false}
                showLabels={true}
                subheader={""}
                termsCopy={""}
                withContainer={true}
                locationId={fishermanBusiness.primaryLocation._id}
                businessId={fishermanBusiness._id}
                businessType={fishermanBusiness.type}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            verticalAlign={"top"}
            centered={true}
            column={"4"}
            className={"additional-infos component-section-container"}
          >
            <Grid.Row stretched={true}>
              <Grid.Column width={4} textAlign={"center"}>
                <Image
                  src={
                    "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/snapchat_setup_2_S0fKLdn.jpeg"
                  }
                  centered={true}
                  size={"medium"}
                  style={{ height: "400px", width: "auto" }}
                />
                <h3>Full Service</h3>
                <List
                  bulleted={true}
                  items={[
                    "Formal catering service",
                    "Chefs that setup, prepare food, and cleanup",
                    "Additional staffing fee (contact for pricing)",
                  ]}
                />
              </Grid.Column>
              <Grid.Column width={4} textAlign={"center"}>
                <div>
                  <div>
                    <Image
                      src={
                        "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/pre-built_buns.jpeg"
                      }
                      centered={true}
                      size={"medium"}
                      style={{ height: "400px", width: "auto" }}
                    />
                  </div>
                  <h3>Buffet</h3>
                  <List
                    bulleted={true}
                    items={[
                      "Casual catering service",
                      "Dishes laid out in a line for guests to self-serve",
                      "Optional chafers for buy/rent",
                      "Cost Effective",
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={4} textAlign={"center"}>
                <div>
                  <div>
                    <Image
                      src={
                        "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/360151167_268067332512350_4968266280429794117_n.jpg"
                      }
                      centered={true}
                      size={"medium"}
                      style={{ height: "400px", width: "auto" }}
                    />
                  </div>
                  <h3>Pre-Packaged</h3>
                  <List
                    bulleted={true}
                    items={[
                      "Popular office catering service",
                      "Grab, go, and eat (great for meetings)",
                      "Optional sticker labeling (customer name, dish, allergens)",
                    ]}
                  />
                </div>
              </Grid.Column>
              <Grid.Column width={4} textAlign={"center"}>
                <div>
                  <Image
                    src={
                      "https://fisherman.gumlet.io/public/5eedb6d5-88ab-476e-935a-9d290945e7b7/front_tent_setup.png"
                    }
                    centered={true}
                    size={"medium"}
                    style={{ height: "400px", width: "auto" }}
                  />
                  <h3>Popup</h3>
                  <List
                    bulleted={true}
                    items={[
                      "Outdoor tent setup (night market style)",
                      "Guest paid options available",
                      "Flexible setups (backyard, park, patio, etc.)",
                    ]}
                  />
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid
            stackable={true}
            verticalAlign={"top"}
            centered={true}
            textAlign={"center"}
            column={"2"}
            className={
              "additional-services-section component-section-container"
            }
          >
            <Grid.Column
              tablet={16}
              computer={8}
              largeScreen={8}
              textAlign={"center"}
            >
              <Grid.Row stretched={true}>
                <Grid.Column width={16} textAlign={"center"}>
                  <h3>Chafing Service</h3>
                  <div>
                    Available upon request. 20% additional fee will be added to
                    the Food&Beverage Total. Includes table linens, serving
                    utensils, chafers, allergen/dietary labels. Includes
                    chef/staff to deliver/setup/cleanup. Does not include
                    chef/staff to serve food (Full Service Catering). Please
                    inquire regarding Full Service Catering.
                  </div>
                </Grid.Column>
                <Grid.Column width={16} textAlign={"center"}>
                  <h3>Miscellaneous Rentals/Purchases</h3>
                  <div>
                    Table Linens, Folding Tables, Disposable Serving Utensils,
                    Individual Disposable Utensils/Napkins.Plates, Trash
                    available for additional fee. Please inquire.
                  </div>
                </Grid.Column>
                <Grid.Column width={16} textAlign={"center"}>
                  <h3>Drinks</h3>
                  <div>
                    Off menu specialty catering drinks are available. Please
                    inquire.
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column
              tablet={16}
              computer={8}
              largeScreen={8}
              textAlign={"center"}
            >
              <h3>Delivery/Setup fee based on distance</h3>
              <Table celled={true}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Minimum Delivery Fee</Table.HeaderCell>
                    <Table.HeaderCell>Delivery Fee Percentage</Table.HeaderCell>
                    <Table.HeaderCell>Maximum Delivery Fee</Table.HeaderCell>
                    <Table.HeaderCell>Distance</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>$30</Table.Cell>
                    <Table.Cell>10%</Table.Cell>
                    <Table.Cell>$120</Table.Cell>
                    <Table.Cell>0-10 miles</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>$40</Table.Cell>
                    <Table.Cell>10%</Table.Cell>
                    <Table.Cell>$120</Table.Cell>
                    <Table.Cell>10-20 miles</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>$50</Table.Cell>
                    <Table.Cell>10%</Table.Cell>
                    <Table.Cell>$120</Table.Cell>
                    <Table.Cell>20-30 miles</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>$60</Table.Cell>
                    <Table.Cell>10%</Table.Cell>
                    <Table.Cell>$120</Table.Cell>
                    <Table.Cell>30-40 miles</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>$70</Table.Cell>
                    <Table.Cell>10%</Table.Cell>
                    <Table.Cell>$120</Table.Cell>
                    <Table.Cell>40-50 miles</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            verticalAlign={"top"}
            className={"map-section"}
            textAlign={"center"}
            centered={true}
          >
            <Grid.Row stretched={true}>
              <Grid.Column
                width={16}
                className={"contact-map-section-container"}
              >
                <Contact
                  businessName={fishermanBusiness.name}
                  phoneNumber={fishermanBusiness.primaryLocation.phoneNumber}
                  email={fishermanBusiness.primaryLocation.email}
                  hours={fishermanBusiness.primaryLocation.hours}
                  address={{
                    street: fishermanBusiness.primaryLocation.street,
                    city: fishermanBusiness.primaryLocation.city,
                    state: fishermanBusiness.primaryLocation.state,
                    zip: fishermanBusiness.primaryLocation.zipCode,
                  }}
                  as={"text"}
                  buttonColor={"secondary"}
                  header={"Contact"}
                  image={""}
                  showMap={true}
                />
                <div className={"ui container"}>
                  <div className={"ui stackable grid"}>
                    <div
                      className={"eleven wide column custom contact-graphic"}
                    />
                    <div
                      className={
                        "middle aligned four wide column contact-content"
                      }
                    >
                      <header className={"ui header"}>Catering</header>
                      <div className={"item"}>
                        <div className={"content"}>
                          <header className={"header h5 day-label"}>
                            Monday-Sunday
                          </header>
                          <div>8:00am - 11:00pm</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
        phoneNumber
        email
        hours {
          open
          close
          label
          day
        }
        street
        city
        state
        zipCode
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
        }
        componentIdentifier
      }
    }
  }
`;
